<template>
      <div class="page-content-main">
          <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" class="margin-top">
              <el-form-item label="关于我们" prop="about">
                  <editor v-model="formData.about" :height="300"/>
              </el-form-item>
              <el-form-item>
                  <el-button type="primary" @click="submitForm('form')">确定</el-button>
                  <el-button type="" @click="$router.back()">返回</el-button>
              </el-form-item>
          </el-form>
      </div>
</template>

<script>
    export default {
        name: "add",
        data() {
            return {
                formData: {
	                about:'',
                },
                rules: {
                  about:[{required:true,message:'内容必填'}],
                },
            };
        },
        mounted(){
            this.getDetail();
        },
        methods: {
            submitForm(formName) {
                let _this = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
	                    _this.$http.request({
		                    url:'/SystemSetting/edit',
		                    datas:_this.formData,
		                    success:(res)=>{
		                    	if(res === 'ok'){
				                    _this.$message.success('修改成功');
                                }else{
				                    _this.$message.error('修改失败');
                                }
		                    	// _this.$message.info(res.msg);
			                    // _this.$router.replace({path: '/cate/list'})
		                    }
	                    });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
	        getDetail() {
		        let _this = this;

		        let _skeys = [];

		        for(let k in this.formData){
			        _skeys.push(k);
                }

		        _this.$http.request({
			        url:'/SystemSetting/getSystem',
			        datas:{skey:_skeys},
			        success:(res)=>{
			        	_this.formData = res;
			        }
		        });
	        }
        }
    }
</script>
